import React, { useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Services from "./components/Services";
import ContactForm from "./components/ContactForm";
import AboutUsCTA from "./components/AboutUsCTA";
import Header from "./components/Header";
import BootcampPage from "./components/Bootcamp/BootcampPage";

function App() {
  const theme = useTheme();
  const contactFormRef = useRef(null);

  const homePageContent = (
    <Box sx={{ paddingTop: theme.spacing(8) }}>
      <AboutUsCTA
        scrollToContact={() =>
          contactFormRef.current.scrollIntoView({
            behavior: "smooth",
          })
        }
      />
      <Services />
      <ContactForm ref={contactFormRef} />
    </Box>
  );

  return (
    <Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Becoming Mountains | Blockchain Consulting Services in Vancouver
        </title>
        <link rel="canonical" href="http://becomingmountains.com/" />
        <meta
          name="description"
          content="Expert Blockchain Consulting in Vancouver. Discover how our blockchain solutions can transform your business."
        />
        <meta
          name="description"
          content="Expert DevOps Consulting in Vancouver. Discover how our devops solutions can transform your business."
        />
      </Helmet>
      <Router>
        <Header />

        <Routes>
          <Route exact path="/" element={homePageContent} />
          <Route path="/bootcamp" element={<BootcampPage />} />
        </Routes>
      </Router>
    </Box>
  );
}

export default App;
