import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School"; // For general education
import CodeIcon from "@mui/icons-material/Code"; // For coding and development
import GroupIcon from "@mui/icons-material/Group"; // For community and networking
import TrendingUpIcon from "@mui/icons-material/TrendingUp"; // For career advancement
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn"; // For projects and practical experience
import SupportAgentIcon from "@mui/icons-material/SupportAgent"; // For mentorship and support

function Highlights() {
  const services = [
    {
      icon: <SchoolIcon sx={{ fontSize: 60, color: "#5cb85c" }} />,
      title: "Comprehensive Curriculum",
      description:
        "Explore an extensive curriculum covering blockchain fundamentals, cryptocurrency, smart contracts, and more, tailored to foster a deep understanding of blockchain technology.",
    },
    {
      icon: <CodeIcon sx={{ fontSize: 60, color: "#5cb85c" }} />,
      title: "Hands-On Development",
      description:
        "Gain practical experience with hands-on development projects, coding sessions, and collaborative workshops designed to give you a real-world feel of blockchain application.",
    },
    {
      icon: <GroupIcon sx={{ fontSize: 60, color: "#5cb85c" }} />,
      title: "Networking & Community",
      description:
        "Join a vibrant community of blockchain enthusiasts and professionals. Build your network through our community events, seminars, and collaborative projects.",
    },
    {
      icon: <TrendingUpIcon sx={{ fontSize: 60, color: "#5cb85c" }} />,
      title: "Career Advancement",
      description:
        "With our dedicated career support services, including job placement assistance and career counseling, we help you prepare for and navigate the evolving blockchain job market.",
    },
    {
      icon: <AssignmentTurnedInIcon sx={{ fontSize: 60, color: "#5cb85c" }} />,
      title: "Practical Projects",
      description:
        "Work on real-world projects and case studies that will give you a portfolio of demonstrable work, showing your ability to solve practical problems with blockchain technology.",
    },
    {
      icon: <SupportAgentIcon sx={{ fontSize: 60, color: "#5cb85c" }} />,
      title: "Expert Mentorship",
      description:
        "Learn from industry experts and gain insights from seasoned professionals. Our mentorship program offers guidance, advice, and valuable feedback throughout your learning journey.",
    },
  ];

  const renderGrid = () => {
    return (
      <Grid
        container
        spacing={6}
        justifyContent="center"
        style={{ width: "90%", textAlign: "center", marginBottom: 20 }}
      >
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                boxShadow: 3,
                borderRadius: "15px",
                height: "100%",
              }}
            >
              <CardMedia
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 140,
                }}
              >
                {service.icon}
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {service.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        minHeight: "65vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: "#5cb85c",
          marginBottom: 4,
          textAlign: "center",
          fontWeight: "bold",
          marginTop: 5,
        }}
      >
        Highlights
      </Typography>
      {renderGrid()}
    </Box>
  );
}

export default Highlights;
