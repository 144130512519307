import React from "react";

import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  useScrollTrigger,
  Slide,
} from "@mui/material";
import { Link } from "react-router-dom";

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function Header(props) {
  return (
    <HideOnScroll {...props}>
      <AppBar
        position="fixed"
        color="default"
        elevation={4}
        sx={{
          bgcolor: "#ffffff",
          color: "#333333",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Box
                component="img"
                sx={{
                  height: 60,
                  mr: 2,
                }}
                alt="Becoming Mountains Logo"
                src="/logo.png"
              />
            </Link>
            <Typography
              variant="h6"
              color="primary"
              sx={{
                color: "#000",
                fontWeight: "bold",
                fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
              }}
              noWrap
            >
              Becoming Mountains Blockchain Consulting
            </Typography>

            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "#333333",
                marginLeft: 20,
              }}
            >
              <Typography variant="h6">Home</Typography>
            </Link>
            <Link
              to="/bootcamp"
              style={{
                textDecoration: "none",
                color: "#333333",
                marginLeft: 20,
              }}
            >
              <Typography variant="h6">Bootcamp</Typography>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}

export default Header;
