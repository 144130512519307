import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import BlockchainIcon from "@mui/icons-material/AccountBalance";
import SmartContractIcon from "@mui/icons-material/Code";
import ImplementationIcon from "@mui/icons-material/Build";
import DataSecurityIcon from "@mui/icons-material/Security"; // Renamed NewServiceIcon2
import MarketAnalysisIcon from "@mui/icons-material/TrendingUp"; // Renamed NewServiceIcon3
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";

function Services() {
  const services = [
    {
      icon: <CurrencyBitcoinIcon sx={{ fontSize: 60, color: "#5cb85c" }} />, // Use the Bitcoin icon here
      title: "Cryptocurrency Integration",
      description:
        "Start accepting cryptocurrency payments and integrate digital currencies into your business operations. Expand your payment options and tap into the growing crypto market with our expertise.",
    },
    {
      icon: <BlockchainIcon sx={{ fontSize: 60, color: "#5cb85c" }} />,
      title: "Blockchain Strategy",
      description:
        "We provide comprehensive blockchain strategy consulting to help businesses integrate blockchain technology into their operations. ",
    },
    {
      icon: <SmartContractIcon sx={{ fontSize: 60, color: "#5cb85c" }} />,
      title: "Smart Contract Development",
      description:
        "Our team specializes in smart contract development, enabling automation and trust in your transactions. We create secure and efficient smart contracts tailored to your specific business needs.",
    },
    {
      icon: <ImplementationIcon sx={{ fontSize: 60, color: "#5cb85c" }} />,
      title: "Blockchain Implementation",
      description:
        "We offer end-to-end blockchain implementation services, ensuring a seamless transition to blockchain technology. From planning and development to deployment and maintenance, we've got you covered.",
    },
    {
      icon: <DataSecurityIcon sx={{ fontSize: 60, color: "#5cb85c" }} />,
      title: "Data Security",
      description:
        "Protect your valuable data with our state-of-the-art data security solutions. We employ the latest encryption and security measures to keep your information safe from threats.",
    },
    {
      icon: <MarketAnalysisIcon sx={{ fontSize: 60, color: "#5cb85c" }} />,
      title: "Market Analysis",
      description:
        "Stay ahead of the competition with our market analysis services. We provide insights and trends in the blockchain industry to help you make informed decisions and maximize your business potential.",
    },
  ];

  const renderGrid = () => {
    return (
      <Grid
        container
        spacing={6}
        justifyContent="center"
        style={{ width: "90%", textAlign: "center", marginBottom: 20 }}
      >
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                boxShadow: 3,
                borderRadius: "15px",
                height: "100%",
              }}
            >
              <CardMedia
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 140,
                }}
              >
                {service.icon}
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {service.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        // marginTop: 5,
        minHeight: "65vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // marginBottom: 5,
        backgroundColor: "#f5f5f5",
        // marginTop: { xs: 2, sm: 3, md: 4 }, // Adjust these values as needed
        // marginBottom: { xs: 2, sm: 3, md: 4 }, //
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: "#5cb85c",
          marginBottom: 4,
          textAlign: "center",
          fontWeight: "bold",
          marginTop: 5,
        }}
      >
        Our Services
      </Typography>
      {renderGrid()}
    </Box>
  );
}

export default Services;
