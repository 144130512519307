import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";

function AboutBootcamp({ scrollToContact }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        padding: isMobile ? 2 : 4,
        textAlign: "center",
        backgroundColor: "#f0f0f0",
        minHeight: isMobile ? "50vh" : "65vh",
        backgroundImage: "url(/logo-test.png)",
        backgroundSize: isMobile ? "cover" : "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        color: "#000",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {/* <Box
        component="img"
        sx={{
          width: "100%",
          height: "auto",
          maxHeight: { xs: "60vh", sm: "45vh", md: "30vh" },
          objectFit: "contain",
          marginBottom: 3,
          opacity: 0.5, // Adjust opacity
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
        }}
        alt="Becoming Mountains Logo"
        src="/logo-large.png" // A larger version of your logo
      />{" "} */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the color and opacity as needed
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          maxWidth: { sm: "90%", md: "75%", lg: "60%" },
          textAlign: "center",
          position: "relative", // Ensure this box is above the overlay
          zIndex: 2,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            marginBottom: 2,
            color: "white",
            textShadow: "1px 1px 4px rgba(0,0,0,0.7)",
          }}
        >
          Discover Our Blockchain Bootcamp
        </Typography>
        <Typography
          variant="h6"
          sx={{
            marginBottom: 3,
            color: "white",
            textShadow: "1px 1px 4px rgba(0,0,0,0.7)",
          }}
        >
          Join our immersive Blockchain Bootcamp designed to equip you with the
          skills and knowledge to excel in the world of blockchain technology.
          Our program is tailored for both beginners and experienced
          professionals, offering hands-on training, real-world project
          experience, and expert mentorship.
        </Typography>
        <Typography
          variant="h6"
          sx={{
            marginBottom: 3,
            color: "white",
            textShadow: "1px 1px 4px rgba(0,0,0,0.7)",
          }}
        >
          Whether you're looking to start a career in blockchain, enhance your
          existing skills, or bring blockchain solutions to your business, our
          bootcamp is the perfect starting point.
        </Typography>
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={scrollToContact}
          >
            Join the waitlist{" "}
          </Button>
        </Grid>{" "}
      </Box>
    </Box>
  );
}

export default AboutBootcamp;
