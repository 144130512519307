import React, { useRef } from "react";
import { Box, useTheme } from "@mui/material";
import AboutBootcamp from "./AboutBootcamp";
import Highlights from "./Highlights";
import BootcampInterestForm from "./BootcampInterestForm";

function BootcampPage() {
  const theme = useTheme();
  const contactFormRef = useRef(null);

  return (
    <Box sx={{ paddingTop: theme.spacing(8) }}>
      <AboutBootcamp
        scrollToContact={() =>
          contactFormRef.current.scrollIntoView({
            behavior: "smooth",
          })
        }
      />
      <Highlights />
      <BootcampInterestForm ref={contactFormRef} />
      {/* Add more content here */}
    </Box>
  );
}

export default BootcampPage;
